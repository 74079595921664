import React from "react";
import styled from "styled-components";
import Paragraph from "../components/blog/Paragraph";
import BlogTitle from "../components/blog/BlogTitle";
import ImageWithCaption from "../components/blog/ImageWithCaption";
import BlogHeading2 from "../components/blog/BlogHeading2";
import InternalLink from "../components/blog/InternalLink";
import ExternalLink from "../components/blog/ExternalLink";
import BlockQuote from "../components/blog/BlockQuote";
import UnorderedList from "../components/blog/UnorderedList";
import UnorderedListItem from "../components/blog/UnorderedListItem";
import {StaticImage} from "gatsby-plugin-image";
import SEO from "../components/SEO";
import BlogHeading3 from "../components/blog/BlogHeading3";

const BlogPostWrapper = styled.main`
  padding: var(--main-padding-mob);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledMainContent = styled.div`  
  max-width: 750px; 
`;

const HeroSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
`;


const BlogPost = ({location}) =>{
    return <BlogPostWrapper> <StyledMainContent>
        <SEO
            title="The Low FODMAP Shopping List Done Right"
            description="A low FODMAP diet can be effective in managing IBS, especially because the end result is a personalized diet that can be maintained over time."
            location={location}
            pageType="article"
        />

        <HeroSection>
            <BlogTitle>
                The Low FODMAP Shopping List Done Right + FREE Printables
            </BlogTitle>
            <ImageWithCaption>
                <StaticImage src="../assets/images/blogs/Low-FODMAP Shopping-List.jpg" alt="The Low FODMAP Shopping List Done Right"/>
                <em>Photo by <ExternalLink to="https://unsplash.com/@ferhadd?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Farhad Ibrahimzade</ExternalLink> on <ExternalLink to="https://unsplash.com/s/photos/meat-and-vegetables?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</ExternalLink></em>
            </ImageWithCaption>
        </HeroSection>
        <Paragraph>
            These days more and more people are suffering from gut issues, including irritable bowel syndrome (IBS) and intestinal bacterial overgrowth (SIBO).
        </Paragraph>
        <Paragraph>
            Although the exact cause of these conditions is unknown, our increasingly stressful day to day lives and a lack of healthy nutrition could worsen their digestive symptoms.
        </Paragraph>
        <Paragraph>
        One way to deal with IBS and SIBO is diet modification, and following a low FODMAP diet is becoming an increasingly more prevalent choice. Studies have shown that this diet can reduce IBS symptoms in up to <ExternalLink to="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4918736/">86% of people suffering from the condition.</ExternalLink>
        </Paragraph>
        <Paragraph>
            However, starting a rigorous food program can be daunting, especially if you feel confused as to where to start exactly and don't have enough time to dedicate to the process.
        </Paragraph>
        <Paragraph>
            <b>Key takeaways from this article:</b>
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem>The definition of a low FODMAP diet</UnorderedListItem>
            <UnorderedListItem>What the low FODMAP diet can and should be used for</UnorderedListItem>
            <UnorderedListItem>Lists of high FODMAP foods to avoid</UnorderedListItem>
            <UnorderedListItem>Lists of low FODMAP foods to eat - FREE printables!</UnorderedListItem>
            <UnorderedListItem>Health implications of following a low FODMAP diet (both positive and negative)</UnorderedListItem>
        </UnorderedList>
        <BlogHeading2>
            What is a low FODMAP diet?
        </BlogHeading2>
        <Paragraph>
            A low fodmap diet is a diet designed specifically for individuals struggling with irritable bowel syndrome (IBS) and intestinal bacterial overgrowth (SIBO).
        </Paragraph>
        <Paragraph>
            FODMAP stands for foods containing fermentable oligosaccharides (oligos), disaccharides, monosaccharides, and polyols. These represent a type of carbohydrates that are not so easy to digest when eaten in large quantities. These types of carbs can cause irritation of the gut for people with IBS even when consumed in lower doses.
        </Paragraph>
        <Paragraph>
            The basic premise of the low FODMAP diet is eliminating most foods from our diet that are high in FODMAP so that our gut can rest. That way, we can get a sense of which foods are actually causing our gut problems.
        </Paragraph>
        <BlogHeading2>
            How to start a low FODMAP diet?
        </BlogHeading2>
        <Paragraph>
            The low FODMAP diet consists of three stages:
        </Paragraph>
        <BlogHeading3>
            Stage 1: Restriction
        </BlogHeading3>
        <Paragraph>
            The first stage usually lasts from 2 to no longer than 6 weeks, and requires avoidance of all high FODMAP foods (which we outlined below). You will need to follow a strict low FODMAP diet during this time. 
        </Paragraph>
        <Paragraph>
            Signs of improvement should be felt within <ExternalLink to="https://onlinelibrary.wiley.com/doi/full/10.1111/jgh.13686">4 weeks.</ExternalLink>
        </Paragraph>
        <BlogHeading3>
            Stage 2: Reintroduction
        </BlogHeading3>
        <Paragraph>
            Next comes a testing stage, where you reintroduce each previously eliminated high FODMAP food for two to three days in order to get a sense of which ones are causing your intestinal issues.
        </Paragraph>
        <Paragraph>
            Its length can vary, but on average, the reintroduction stage lasts for around 8 weeks.
        </Paragraph>
        <Paragraph>
            Although there is no scientifically proven best strategy to do this, there are <ExternalLink to="https://med.virginia.edu/ginutrition/wp-content/uploads/sites/199/2018/05/Low_FODMAP_Diet_12.16.pdf">three ways</ExternalLink> in which you can reintroduce high FODMAP foods:
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem><b>Option 1:</b> Start reintroducing lower FODMAP foods first, gradually leading to high FODMAP foods at the end of the diet.</UnorderedListItem>
            <UnorderedListItem><b>Option 2:</b> Start with reintroducing high FODMAP foods right away, then moving on to lower FODMAP foods.</UnorderedListItem>
            <UnorderedListItem><b>Option 3:</b> Start with reintroducing your favorite FODMAP foods first, regardless of whether they are high or low FODMAP.</UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            Stage 3: Personalization
        </BlogHeading3>
        <Paragraph>
            In the final stage, you begin to implement what you learned in stage 2, and modify a low FODMAP diet tailored to your personal tolerance. 
        </Paragraph>
        <Paragraph>
            This is when you get back to your normal diet while at the same time avoiding high FODMAP foods you identified as your triggers.
        </Paragraph>
        <BlogHeading2>
            What to avoid on a low FODMAP diet?
        </BlogHeading2>
        <Paragraph>
            There are quite a few foods we use in our daily diets which are high FODMAP and could contribute to bowel irritability.
        </Paragraph>
        <Paragraph>
            Recommended high FODMAP foods to avoid include:
        </Paragraph>
        <BlogHeading3>
            Grains
        </BlogHeading3>
        <Paragraph>
            Grains, especially <ExternalLink to="https://repository.rothamsted.ac.uk/item/97788/fodmaps-in-wheat">wheat</ExternalLink>, are responsible for a major amount of FODMAP in our diets. That is because grains are typically present in most of our meals.
        </Paragraph>
        <Paragraph>
            High FODMAP grains to avoid are:
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem><b>Wheat</b></UnorderedListItem>
            <UnorderedListItem><b>Rye</b></UnorderedListItem>
            <UnorderedListItem><b>Barley</b></UnorderedListItem>
            <UnorderedListItem><b>Amaranth</b></UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            Vegetables
        </BlogHeading3>
        <Paragraph>
            Vegetables are some of the healthiest foods one can eat.
        </Paragraph>
        <Paragraph>
            However, some of them, especially garlic and onion, contain a high concentration of FODMAP and must be avoided during a low FODMAP diet.
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem><b>Garlic</b></UnorderedListItem>
            <UnorderedListItem><b>Onion</b></UnorderedListItem>
            <UnorderedListItem><b>Cauliflower</b></UnorderedListItem>
            <UnorderedListItem><b>Bell peppers</b></UnorderedListItem>
            <UnorderedListItem><b>Mushrooms</b></UnorderedListItem>
            <UnorderedListItem><b>Brussels sprouts</b></UnorderedListItem>
            <UnorderedListItem><b>Butternut squash</b></UnorderedListItem>
            <UnorderedListItem><b>Beets</b></UnorderedListItem>
            <UnorderedListItem><b>Asparagus</b></UnorderedListItem>
            <UnorderedListItem><b>Broccoli</b></UnorderedListItem>
            <UnorderedListItem><b>Cauliflower</b></UnorderedListItem>
            <UnorderedListItem><b>Cabbage</b></UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            Fruits
        </BlogHeading3>
        <Paragraph>
            Any foods that contain a high amount of fructose should be avoided on this diet.
        </Paragraph>
        <Paragraph>
            This includes some otherwise healthy fruits which are not allowed because they can contain FODMAPs such as monosaccharides (fructose) and polyols.
        </Paragraph>
        <Paragraph>
            High FODMAP fruit options are:
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem><b>Apples</b></UnorderedListItem>
            <UnorderedListItem><b>Cherries</b></UnorderedListItem>
            <UnorderedListItem><b>Blackberries</b></UnorderedListItem>
            <UnorderedListItem><b>Peaches</b></UnorderedListItem>
            <UnorderedListItem><b>Mangoes</b></UnorderedListItem>
            <UnorderedListItem><b>Watermelon</b></UnorderedListItem>
            <UnorderedListItem><b>Pears</b></UnorderedListItem>
            <UnorderedListItem><b>Plums</b></UnorderedListItem>
            <UnorderedListItem><b>Nectarines</b></UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            Dairy
        </BlogHeading3>
        <Paragraph>
            Lactose is the biggest reason common dairy products such as milk and yogurt are not allowed on a low FODMAP diet.
        </Paragraph>
        <Paragraph>
            Avoid all of these dairy products on a low FODMAP diet:
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem><b>Milk</b></UnorderedListItem>
            <UnorderedListItem><b>Buttermilk</b></UnorderedListItem>
            <UnorderedListItem><b>Yogurt</b></UnorderedListItem>
            <UnorderedListItem><b>Cottage cheese</b></UnorderedListItem>
            <UnorderedListItem><b>Ricotta cheese</b></UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            Legumes & nuts
        </BlogHeading3>
        <Paragraph>
            Legumes contain FODMAPs called galactans in high doses and shouldn't be consumed on a low FODMAP diet. 
        </Paragraph>
        <Paragraph>
            Nuts can also be high in FODMAP, especially cashews and pistachios.
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem><b>Lentils</b></UnorderedListItem>
            <UnorderedListItem><b>Beans</b></UnorderedListItem>
            <UnorderedListItem><b>Soybeans</b></UnorderedListItem>
            <UnorderedListItem><b>Cashews</b></UnorderedListItem>
            <UnorderedListItem><b>Pistachios</b></UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            Sweeteners
        </BlogHeading3>
        <Paragraph>
            Similar to fruits, many other sweet foods that contain a lot of fructose are not allowed on a low FODMAP diet. These include:
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem><b>Honey</b></UnorderedListItem>
            <UnorderedListItem><b>Agave nectar</b></UnorderedListItem>
            <UnorderedListItem><b>Malt extract</b></UnorderedListItem>
            <UnorderedListItem><b>High fructose corn syrup</b></UnorderedListItem>
            <UnorderedListItem><b>Sugar-free sweeteners (sorbitol, mannitol, xylitol, or isomalt)</b></UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            Drinks
        </BlogHeading3>
        <Paragraph>
            Many common drinks are heavily processed and contain FODMAPs such as galactans and fructose.
        </Paragraph>
        <Paragraph>
            Especially watch out for these:
        </Paragraph>
        <UnorderedList>
           <UnorderedListItem><b>Vegan milks (oat milk, soy milk, almond milk)</b></UnorderedListItem>
           <UnorderedListItem><b>Kombucha</b></UnorderedListItem>
           <UnorderedListItem><b>Fruit juice (apple juice, orange juice)</b></UnorderedListItem>
           <UnorderedListItem><b>Coconut water</b></UnorderedListItem>
           <UnorderedListItem><b>Chai tea</b></UnorderedListItem>
           <UnorderedListItem><b>Sweet alcoholic drinks (dessert wine, rum)</b></UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            Processed food
        </BlogHeading3>
        <Paragraph>
            Processed foods are generally not good for your gut health, so be careful of the amount of these you consume. 
        </Paragraph>
        <Paragraph>
            Beware of high FODMAP foods that at first glance might not contain FODMAPs - read each food label carefully.
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem><b>Ice cream</b></UnorderedListItem>
            <UnorderedListItem><b>Cereal</b></UnorderedListItem>
            <UnorderedListItem><b>Bread</b></UnorderedListItem>
            <UnorderedListItem><b>Crackers</b></UnorderedListItem>
            <UnorderedListItem><b>Processed meats</b></UnorderedListItem>
            <UnorderedListItem><b>Chewing gums</b></UnorderedListItem>
            <UnorderedListItem><b>Biscuits</b></UnorderedListItem>
            <UnorderedListItem><b>Cakes</b></UnorderedListItem>
            <UnorderedListItem><b>Pastries</b></UnorderedListItem>
        </UnorderedList>
        <BlogHeading2>
            Low FODMAP foods to eat instead
        </BlogHeading2>
        <Paragraph>
            Even though the above list of high FODMAP content foods to avoid seems a bit scary, there are plenty of alternatives to choose from during your dieting.
        </Paragraph>
        <BlockQuote>
            <b>PRO TIP:</b> As you go through these lists, add the items you want to a <InternalLink to="/#download-section">free shopping list app</InternalLink> on your phone so you don’t forget to buy them on your next shopping trip!
        </BlockQuote>
        <BlogHeading3>
            Animal protein
        </BlogHeading3>
        <Paragraph>
            Omnivores are the lucky ones in this scenario, because all kinds of meat are low in FODMAP.
        </Paragraph>
        <Paragraph>
            The only thing to watch out for here are spices and marinades which can include high FODMAP ingredients such as onion and garlic.
        </Paragraph>
        <Paragraph>
            Meats to add to your low FODMAP grocery list are
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem><b>Chicken</b></UnorderedListItem>
            <UnorderedListItem><b>Beef</b></UnorderedListItem>
            <UnorderedListItem><b>Pork</b></UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            Vegetarian proteins
        </BlogHeading3>
        <Paragraph>
            For those avoiding meat products, there are other options.
        </Paragraph>
        <Paragraph>
            or example, tofu and tempeh are good low FODMAP vegetarian options.
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem><b>Tofu</b></UnorderedListItem>
            <UnorderedListItem><b>Eggs</b></UnorderedListItem>
            <UnorderedListItem><b>Tempeh</b></UnorderedListItem>
            <UnorderedListItem><b>Peanuts (though not technically a nut) and peanut butter (but be careful of other ingredients in it)</b></UnorderedListItem>
            <UnorderedListItem><b>Sesame nuts</b></UnorderedListItem>
            <UnorderedListItem><b>Macadamia nuts</b></UnorderedListItem>
            <UnorderedListItem><b>Walnuts </b></UnorderedListItem>
            <UnorderedListItem><b>Almonds and hazelnuts (only in small portions of 10 nuts or less)</b></UnorderedListItem>
            <UnorderedListItem><b>Seeds (sesame seeds, pumpkin seeds, sunflower seeds, chia seeds, flaxseeds)</b></UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            Vegetables
        </BlogHeading3>
        <Paragraph>
            You can make plenty of soups, salads, and delicious dishes with these low FODMAP veggies:
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem><b>Carrots</b></UnorderedListItem>
            <UnorderedListItem><b>Celery</b></UnorderedListItem>
            <UnorderedListItem><b>Green beans</b></UnorderedListItem>
            <UnorderedListItem><b>Spinach</b></UnorderedListItem>
            <UnorderedListItem><b>Eggplant</b></UnorderedListItem>
            <UnorderedListItem><b>Cucumber</b></UnorderedListItem>
            <UnorderedListItem><b>Sweet potatoes</b></UnorderedListItem>
            <UnorderedListItem><b>Zucchini</b></UnorderedListItem>
            <UnorderedListItem><b>Kale</b></UnorderedListItem>
            <UnorderedListItem><b>Lettuce</b></UnorderedListItem>
            <UnorderedListItem><b>Pumpkin</b></UnorderedListItem>
            <UnorderedListItem><b>Tomatoes (in moderate amounts)</b></UnorderedListItem>Carrots
        </UnorderedList>
        <BlogHeading3>
            Fruits
        </BlogHeading3>
        <Paragraph>
            There are some tasty fruit options for a low FODMAP food list. 
        </Paragraph>
        <Paragraph>
            However, not even low FODMAP fruit should be eaten in large quantities.
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem><b>Lemons</b></UnorderedListItem>
            <UnorderedListItem><b>Limes</b></UnorderedListItem>
            <UnorderedListItem><b>Kiwi</b></UnorderedListItem>
            <UnorderedListItem><b>Unripe bananas</b></UnorderedListItem>
            <UnorderedListItem><b>Pineapple</b></UnorderedListItem>
            <UnorderedListItem><b>Oranges</b></UnorderedListItem>
            <UnorderedListItem><b>Clementines</b></UnorderedListItem>
            <UnorderedListItem><b>Mandarines</b></UnorderedListItem>
            <UnorderedListItem><b>Papaya</b></UnorderedListItem>
            <UnorderedListItem><b>Starfruit</b></UnorderedListItem>
            <UnorderedListItem><b>Berries (blueberries, raspberries, strawberries)</b></UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            Grains
        </BlogHeading3>
        <Paragraph>
            Just because you cannot consume wheat-based foods on a low FODMAP diet does not mean that you cannot include a number of other grain options in your low FODMAP shopping list:
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem><b>Quinoa</b></UnorderedListItem>
            <UnorderedListItem><b>Brown rice</b></UnorderedListItem>
            <UnorderedListItem><b>Oats</b></UnorderedListItem>
            <UnorderedListItem><b>Millet</b></UnorderedListItem>
            <UnorderedListItem><b>Buckwheat</b></UnorderedListItem>
            <UnorderedListItem><b>Polenta</b></UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            Dairy
        </BlogHeading3>
        <Paragraph>
            Generally any dairy that is low in lactose could be considered FODMAP friendly, including but not limited to:
        </Paragraph>

        <UnorderedList>
            <UnorderedListItem><b>Lactose-free milk</b></UnorderedListItem>
            <UnorderedListItem><b>Lactose-free yogurt</b></UnorderedListItem>
            <UnorderedListItem><b>Kefir</b></UnorderedListItem>
            <UnorderedListItem><b>Cheese (feta, cheddar cheese, Swiss cheese, Parmesan, brie)</b></UnorderedListItem>
            <UnorderedListItem><b>Sour cream</b></UnorderedListItem>
            <UnorderedListItem><b>Whipped cream</b></UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            Sweeteners
        </BlogHeading3>
        <Paragraph>
            Be careful with these! Although they are low in FODMAP, too much sugar and sweeteners can negatively affect your health in other ways.
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem><b>Sugar</b></UnorderedListItem>
            <UnorderedListItem><b>Maple syrup</b></UnorderedListItem>
            <UnorderedListItem><b>Glucose</b></UnorderedListItem>
            <UnorderedListItem><b>Sucrose</b></UnorderedListItem>
            <UnorderedListItem><b>Artificial sweeteners (aspartame, saccharin, and stevia)</b></UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            Drinks
        </BlogHeading3>
        <Paragraph>
            Can you believe that beer is a low FODMAP ingredient? In fact, you can enjoy a variety of other alcoholic drinks as well, along with tea and coffee. 
        </Paragraph>
        <Paragraph>
            Don't go overboard with alcohol though, only one bottle of beer is allowed on this diet.
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem><b>Tea (green tea, white tea, black tea, peppermint tea, rooibos)</b></UnorderedListItem>
            <UnorderedListItem><b>Alcoholic drinks (beer, wine, vodka, gin)</b></UnorderedListItem>
            <UnorderedListItem><b>Coffee</b></UnorderedListItem>
        </UnorderedList>
        <BlogHeading2>
            Low FODMAP Shopping List Printable
        </BlogHeading2>
        <Paragraph>
            Here's the entire list of low FODMAP foods to print out and use when you next go shopping. (Or you can just <InternalLink to="/#download-section">download a free app</InternalLink> and take your list with you on your phone.)
        </Paragraph>
        <ImageWithCaption>
            <StaticImage src="../assets/images/blogs/post-2022-15-low-fodmap-shopping-list-printable-page-1.png" alt="Low FODMAP shopping list printable page 1"/>
        </ImageWithCaption>
        <ImageWithCaption>
            <StaticImage src="../assets/images/blogs/post-2022-15-low-fodmap-shopping-list-printable-page-2.png" alt="Low FODMAP shopping list printable page 2"/>
        </ImageWithCaption>
        <BlogHeading2>
            Can a low FODMAP diet be used for weight loss?
        </BlogHeading2>
        <Paragraph>
            Although it is likely that weight loss will occur when you follow a low FODMAP diet, it should not be used for the purpose of weight loss.
        </Paragraph>
        <Paragraph>
            The sole purpose of the low FODMAP diet is to help determine the triggers of digestive disorders such as irritable bowel syndrome (IBS) and intestinal bacterial overgrowth (SIBO). It is a process that leads to better long term personalized diet choices.
        </Paragraph>
        <Paragraph>
        Just like other medical diets, such as the <InternalLink to = "/shopping-list-for-insulin-resistance-diet/">insulin resistance</InternalLink> or diabetes diets, a low FODMAP diet should not be attempted without medical supervision.
        </Paragraph>
        <Paragraph>
            If you are looking for ideas on weight loss diets, check out our <InternalLink to="/keto-pantry-staples/">keto-friendly pantry list</InternalLink> for some inspiration!
        </Paragraph>
        <BlogHeading2>
            Is a low FODMAP diet healthy?
        </BlogHeading2>
        <Paragraph>
            Given that it is a highly restrictive diet (also known as elimination diet), it is not advised to follow a low FODMAP diet unless you aim to improve your issues with IBS or SIBO.
        </Paragraph>
        <Paragraph>
            FODMAPs are prebiotics which are essential for a healthy digestive system. Avoiding them altogether for a long period of time could negatively affect the intestinal microbiome.
        </Paragraph>
        <Paragraph>
            At the same time, some well known “food villains” such as sugar are low in FODMAP, but that doesn't mean you can consume them as much as you want.
        </Paragraph>
        <Paragraph>
            In short, low FODMAP diet is healthy only short term when followed specifically for IBS or SIBO and under medical supervision.
        </Paragraph>
        <BlogHeading2>
            Can you be vegan and low FODMAP?
        </BlogHeading2>
        <Paragraph>
            Yes, you can!
        </Paragraph>
        <Paragraph>
            Although following a low FODMAP diet is much easier if you are an omnivore because meat is considered a low FODMAP food, there are still ways to manage on a plant based diet.
        </Paragraph>
        <Paragraph>
            It is important to remember that some go-to vegan and vegetarian options such as vegan milk (soy milk, almond milk, oat milk), legumes, and mushrooms are all high in FODMAP and should best be avoided.
        </Paragraph>
        <Paragraph>
            However, there are still high protein low FODMAP plant-based options such as tofu, nuts, seeds, and quinoa.
        </Paragraph>
        <Paragraph>
            Combined with fresh veggies from the low FODMAP vegetable list and some rice, you'll be able to make well-balanced vegan low FODMAP recipes.
        </Paragraph>
        <BlogHeading2>
            Conclusion
        </BlogHeading2>
        <Paragraph>
            A low FODMAP diet can be effective in managing IBS in many people, especially because the end result is a personalized diet that can be maintained over time and that can reduce the severity of gastrointestinal symptoms such as bloating, abdominal pain, and similar.
        </Paragraph>
        <Paragraph>
            The restriction stage (stage 1) of the diet can be a challenge because there are many foods to be avoided.
        </Paragraph>
        <Paragraph>
            When getting 'back to normal' (stage 3) and incorporating a personalized low FODMAP diet into your everyday lifestyle, it is important to include the avoidance of your trigger foods to a well balanced diet.
        </Paragraph>
        <Paragraph>
            To make your life easier after this process, before your grocery shopping trip, you can simply remove your high FODMAP trigger foods from our <InternalLink to="/healthy-pantry-staples/">healthy pantry list</InternalLink>. If you are vegan, you can do the same with our <InternalLink to ="/vegan-pantry-staples/">vegan pantry list</InternalLink>.
        </Paragraph>
    </StyledMainContent>
    </BlogPostWrapper>
}

export default BlogPost;